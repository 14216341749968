"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.portal = void 0;
exports.portal = {
    buttons: {
        needHelp: "Need Help?",
        chatWithUs: "Chat with us",
    },
    cta: {
        text: {
            openUntil: "We are open until ",
            callUs: " call us on ",
            today: "today",
            callCentreCurrently: "Our call centre is currently ",
            closed: "closed",
            reopen: ", but we open {{day}} at a ",
        },
    },
    reportIssueModal: {
        heading: "Report a Portal Issue",
        content: "This should only be used to report technical issues with the Portal. For any other queries, please close this and select chat with us from the top of the page.",
        form: {
            labels: {
                message: "Please describe the technical issue you are experiencing?",
            },
            placeholders: {
                message: "Enter your message here",
            },
        },
        buttons: {
            submit: "Submit",
            close: "close",
            reportAnIssue: "Report an issue",
        },
        successMessage: "Thank you for your feedback! Our team will review and address the issue shortly.",
    },
};
