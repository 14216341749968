import React from "react";
import { css } from "@emotion/react";
import { CSSInterpolation } from "@emotion/serialize";
import { theme } from "../../theme";
import { styles } from "./Pagination.styles";
import { Icon } from "../../Core/Icon/Icon";
import { Text } from "../../Core/Text/Text";
import { InputSelect } from "../../Core/Form/Inputs/InputSelect/InputSelect";
import { UnstyledButton } from "../../Core/UnstyledButton/UnstyledButton";
import { Box } from "../../Core/Box/Box";
import { MenuPlacement } from "react-select";

export interface PaginationState {
  currentPage: number;
  totalPages: number;
  resultsPerPage: number;
  resultOptions: number[];
}

export interface PaginationProps {
  paginationState: PaginationState;
  onNext: () => void;
  onPrev: () => void;
  onPageChange: (pageNumber: number) => void;
  onResultsChange: (results: number) => void;
  showRowsPerPage?: boolean;
  resultsPerPageMenuPlacement?: MenuPlacement;
  userStyles?: Record<string, CSSInterpolation>;
}

export interface EllipsisComponentProps {
  key: string;
}

/**
 * Generates a paginated array with ellipsis.
 */
const paginateRender = (
  { currentPage, totalPages }: PaginationState,
  renderButton: (pageNumber: number) => unknown,
  renderEllipsis?: (props: EllipsisComponentProps) => React.ReactNode,
) => {
  // Variable delta to maintain a constant length in truncatedButtons array
  const firstDelta = currentPage - 5 < 0 ? Math.abs(currentPage - 5) : 1;
  const lastDelta =
    totalPages - currentPage - 4 < 0
      ? Math.abs(totalPages - currentPage - 4)
      : 1;
  const delta = lastDelta > 1 ? lastDelta : firstDelta;

  const left = currentPage - delta;
  const right = currentPage + delta + 1;
  const range = [];
  const truncatedButtons = [];
  let index;

  for (let i = 1; i <= totalPages; i++) {
    if (i == 1 || i == totalPages || (i >= left && i < right)) {
      range.push(i);
    }
  }

  for (const i of range) {
    if (index) {
      if (i - index === 2) {
        truncatedButtons.push(renderButton(index + 1));
      } else if (i - index !== 1) {
        truncatedButtons.push(
          renderEllipsis ? renderEllipsis({ key: `ellipsis-${i}` }) : "...",
        );
      }
    }
    truncatedButtons.push(renderButton(i));
    index = i;
  }

  return truncatedButtons;
};

export const Ellipsis = ({
  key,
}: EllipsisComponentProps): React.ReactElement => (
  <span key={key} css={css(styles.ellipsis)}>
    ...
  </span>
);

export const Pagination = ({
  paginationState,
  onNext,
  onPrev,
  onPageChange,
  onResultsChange,
  showRowsPerPage = true,
  resultsPerPageMenuPlacement = "bottom",
  userStyles,
}: PaginationProps): React.ReactElement => {
  const { totalPages, currentPage, resultsPerPage, resultOptions } =
    paginationState;

  const renderButton = (pageNumber: number) => (
    <UnstyledButton
      role="tab"
      key={`pagination-link-${pageNumber}`}
      onClick={() => onPageChange(pageNumber)}
      data-testid={`pagination-link-${pageNumber}`}
      styles={
        !!userStyles?.pageNumber
          ? [
              userStyles?.pageNumber,
              currentPage === pageNumber && userStyles?.pageNumberActive,
            ]
          : [
              styles.pageNumber,
              currentPage === pageNumber && styles.pageNumber.active,
            ]
      }
    >
      {pageNumber}
    </UnstyledButton>
  );

  return (
    <Box styles={userStyles?.mainContainer}>
      <Box
        styles={[styles.wrapper, userStyles?.wrapper]}
        data-testid="pagination"
      >
        <Box role="tablist" styles={[styles.carousel, userStyles?.carousel]}>
          <>
            <Box styles={{ paddingLeft: theme.spacing.xsmall2 }}>
              <UnstyledButton
                role="tab"
                onClick={() => onPageChange(1)}
                disabled={currentPage === 1}
                styles={[styles.stepButton, userStyles?.stepButton]}
              >
                <Icon.DoubleChevron
                  color="brand"
                  size="small"
                  css={css(
                    { transform: "rotate(180deg)" },
                    currentPage === 1 && {
                      color: theme.colors.greyscale.lightest,
                    },
                  )}
                />
              </UnstyledButton>
            </Box>
            <Box styles={{ paddingLeft: theme.spacing.xsmall2 }}>
              <UnstyledButton
                role="tab"
                disabled={currentPage === 1}
                styles={[styles.stepButton, userStyles?.stepButton]}
              >
                <Icon.Chevron
                  onClick={onPrev}
                  color="brand"
                  size="small"
                  css={css(
                    { transform: "rotate(180deg)" },
                    currentPage === 1 && {
                      color: theme.colors.greyscale.lightest,
                    },
                  )}
                />
              </UnstyledButton>
            </Box>
            {paginateRender(paginationState, renderButton, Ellipsis)}
            <Box styles={{ paddingRight: theme.spacing.xsmall2 }}>
              <UnstyledButton
                role="tab"
                disabled={currentPage === totalPages}
                styles={[styles.stepButton, userStyles?.stepButton]}
              >
                <Icon.Chevron
                  onClick={onNext}
                  color="brand"
                  size="small"
                  css={css(
                    currentPage === totalPages && {
                      color: theme.colors.greyscale.lightest,
                    },
                  )}
                />
              </UnstyledButton>
            </Box>
            <Box styles={{ paddingRight: theme.spacing.xsmall2 }}>
              <UnstyledButton
                role="tab"
                disabled={currentPage === totalPages}
                onClick={() => onPageChange(totalPages)}
                styles={[styles.stepButton, userStyles?.stepButton]}
              >
                <Icon.DoubleChevron
                  color="brand"
                  size="small"
                  css={css(
                    currentPage === totalPages && {
                      color: theme.colors.greyscale.lightest,
                    },
                  )}
                />
              </UnstyledButton>
            </Box>
          </>
        </Box>
        {showRowsPerPage && (
          <Box
            styles={[
              styles.rowPerPageContainer,
              userStyles?.rowPerPageContainer,
            ]}
          >
            <Text
              fontSize="small"
              styles={[styles.rowPerPageWrap, userStyles?.rowPerPageWrap]}
            >
              Rows per page:
            </Text>
            <Box
              styles={[
                styles.resultsDropdownWrap,
                userStyles?.resultsDropdownWrap,
              ]}
            >
              <InputSelect
                defaultValue={resultsPerPage.toString()}
                options={[
                  ...resultOptions.map((item) => {
                    return {
                      label: String(item),
                      value: String(item),
                    };
                  }),
                ]}
                menuPlacement={resultsPerPageMenuPlacement}
                isSearchable={false}
                onChange={(e) => {
                  onResultsChange(Number(e.target.value));
                }}
                data-testid={"pagination-results-dropdown"}
              />
            </Box>
          </Box>
        )}
      </Box>
    </Box>
  );
};
