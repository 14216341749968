"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.vehicles = void 0;
exports.vehicles = {
    heading: "Vehicles",
    noVehicles: "No vehicles found",
    deleteVehicle: "Are you sure you want to delete this vehicle?",
    table: {
        name: "Vehicle Name",
        registration: "Registration",
        code: "Company Code",
        status: "Status",
        type: "Vehicle Type",
        moreDetails: "More Details",
        actions: "Actions",
    },
    buttons: {
        reset: "Reset",
        search: "Search",
        view: "View",
        delete: "Delete",
        create: "Create",
        addVehicle: "Add Vehicle",
        approve: "Approve",
        save: "Save",
        edit: "Edit",
        addMeta: "Add Meta",
    },
    modal: {
        heading: "Update Vehicle",
        registration: "Registration",
        photos: "Photos",
        noPhotos: "No photos provided",
        createVehicle: "Create Vehicle",
        meta: "Meta",
    },
    toasts: {
        success: "Vehicle approved",
        deleteSuccess: "Vehicle deleted",
        error: "Something went wrong. Please try again.",
        vehicleCreated: "Vehicle created successfully",
    },
    vehiclePreferences: {
        heading: "Vehicle Preferences",
        table: {
            name: "Vehicle Name",
            registration: "Registration",
            type: "Vehicle Type",
            preferredTip: "Preferred Tip",
            defaultDrivers: "Default Driver",
            defaultStartTime: "Start Time",
            channelId: "Channel ID",
            actions: "Actions",
            enabled: "Enabled",
            noStartTime: "No start time",
            noTip: "No tip",
            noDrivers: "No drivers",
            unknown: "Unknown",
        },
        buttons: {
            save: "Save",
            edit: "Edit",
            addCity: "Add City",
            addRegion: "Add Region",
            backToSchedule: "Back to Schedule",
        },
        modal: {
            heading: "Update Vehicle",
            name: "Name",
            enabled: "Enabled",
            city: "City",
            region: "Region",
            enabledDescription: "(Disabling a vehicle marks it as OTR)",
            defaultDrivers: "Default Drivers",
            defaultStartTime: "Default Start Time",
            slackChannelId: "Slack Channel ID",
            preferredTip: "Preferred Tip",
            syncSlack: "Sync Slack",
            syncBc: "Sync BigChange",
            bcResourceId: "BigChange Resource ID",
            bcVehicleId: "BigChange Vehicle ID",
        },
        toasts: {
            success: "Success",
            error: "Something went wrong. Please try again.",
        },
    },
    vehicleJobs: {
        heading: "Vehicle Jobs",
        form: {
            vehicle: "Vehicle",
            date: "Date",
        },
        buttons: {
            reset: "Reset",
            search: "Search",
        },
        table: {
            registration: "Registration",
            totalJobs: "Total Jobs",
            drivers: "Drivers",
            status: "Status",
            reference: "Reference",
            date: "Date",
            postcode: "Postcode",
            bcId: "BC ID",
            statusId: "Status ID",
            assetId: "Asset",
            type: "Type",
            orderTypeId: "Order Type ID",
        },
        errors: {
            noJobs: "No jobs found for this selection. Please try again.",
            fetchJobs: "An error occurred while fetching driver jobs. Please try again.",
            fetchDrivers: "An error occurred while fetching drivers. Please try again.",
            fetchVehicles: "An error occurred while fetching vehicles. Please try again.",
        },
    },
};
