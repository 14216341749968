"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.invoices = void 0;
exports.invoices = {
    noEmails: "No Emails Added",
    noJobs: "No jobs found",
    headings: {
        request: "Request Invoices",
    },
    table: {
        reference: "Reference",
        purchaseOrder: "Purchase Order Number",
        date: "Date",
        postcode: "Postcode",
        status: "Status",
        actions: "Actions",
        notAvailable: "N/A",
    },
    buttons: {
        reset: "Reset",
        search: "Search",
        request: "Request Invoices",
        addEmail: "Add Email",
        remove: "Remove",
        add: "Select",
        addAll: "Select Visible Rows",
        removeAll: "Remove All",
    },
    form: {
        reference: "Reference",
        searchJobs: "Search Jobs",
        purchaseOrder: "Purchase Order Number",
        date: "Date",
        email: "Email",
        enterEmail: "Enter Email",
        selectedEmails: "Selected Emails",
        selectedJobs: "Selected Jobs",
        addJobs: "Select Jobs",
    },
    errors: {
        generic: "Something went wrong. Please try again.",
        emailAdded: "Email already added",
        emailRequired: "Email is required",
        addJobs: "Please add at least one job",
        addEmail: "Please add at least one email",
    },
    modal: {
        success: "Success",
        error: "Your company is unable to request invoices",
        errorMessge: "If this doesn't sound right please get in contact.",
        message: "You should shortly receive an email with the requested invoices attached.",
        okay: "Okay",
        back: "Back to dashboard",
    },
};
