"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.drivers = void 0;
exports.drivers = {
    jobs: {
        headings: {
            read: "Driver Jobs",
        },
        form: {
            headings: {
                driver: "Select a driver",
                driverEmail: "Email",
                totalJobs: "Total Jobs",
                vehicleReg: "Registration",
                jobRef: "Reference",
                jobDate: "Date",
                jobPostcode: "Postcode",
                jobBcId: "BC Id",
                jobStatus: "Status",
                jobStatusId: "Status Id",
                jobVehicleId: "Vehicle Id",
                jobAsset: "Asset",
                jobAssetCrew: "Asset Crew",
                jobType: "Type",
                jobOrderTypeId: "Order Type Id",
            },
        },
        buttons: {
            reset: "Reset",
        },
        errors: {
            notFoundDriver: "The selected user isn't a driver",
            noJobsFound: "There are no jobs associated with this driver today, please select another driver",
            noVehicleIdFound: "Could not retrieve vehicle id",
        },
    },
    allocateJobs: {
        headings: {
            bulkAllocation: "Bulk Allocation",
        },
        labels: {
            vehicle: "Vehicle",
            jobReference: "Job Reference",
        },
        buttons: {
            submit: "Submit",
            search: "Search",
            reset: "Reset",
        },
        titles: {
            jobReference: "Job Reference:",
            collectionDate: "Collection Date:",
            postcode: "Postcode:",
            orderType: "Order Type:",
            description: "Description:",
            loadingSubcontractors: "Loading subcontractors",
        },
        errors: {
            jobIsNotFound: "Job with this reference is not found",
        },
    },
    notifications: {
        headings: {
            readNotification: "Driver Notifications",
        },
        form: {
            headings: {
                driver: "Driver*",
                notificationTitle: "Title",
                notificationBody: "Body",
                notificationJSON: "JSON Data*",
                allocatedDrivers: "Allocated Drivers",
            },
            placeholders: {
                driver: "Select a driver",
                notificationTitle: "Enter Title",
                notificationBody: "Enter Body",
                notificationJSON: {
                    key: "Enter Key",
                    value: "Enter Value",
                },
            },
        },
        table: {
            notificationJSON: {
                headings: {
                    key: "Key",
                    value: "Value",
                },
            },
            actions: {
                action: "Action",
                add: "Add",
                delete: "Delete",
            },
        },
        buttons: {
            add: "Add",
            reset: "Reset",
            notification: "Send Notification",
        },
        errors: {
            noDriverSelected: "Please select at least one driver",
            driversNotification: "{{error}}, please click reset and try again",
        },
        success: {
            sentNotificationDrivers: "Successfully sent notifications to selected drivers",
        },
        textSelectDropDown: "Listed drivers only include those with a device token",
    },
    create: {
        headings: {
            create: "Create Driver",
        },
        form: {
            headings: {
                driverEmail: "Email",
                driverFirstName: "First Name",
                driverLastName: "Last Name",
                driverPhoneNumber: "Phone Number",
                driverTemporaryPassword: "Temporary Password",
            },
            placeholders: {
                driverEmail: "Enter Email",
                driverFirstName: "Enter First Name",
                driverLastName: "Enter Last Name",
                driverPhoneNumber: "Enter Phone Number",
                driverTemporaryPassword: "Enter Temporary Password",
            },
        },
        buttons: {
            reset: "Reset",
            submit: "Submit",
        },
        links: {
            allocate: "Allocate Drivers",
        },
        errors: {
            createDriver: "Cannot create new driver account, please click reset and try again",
        },
        success: {
            successCreatedNewDriver: "Successfully created new driver account",
        },
    },
    allocate: {
        headings: {
            driverAllocation: "Driver Allocation",
            allocatedDrivers: "Allocated Drivers",
        },
        form: {
            headings: {
                driver: "Driver",
                vehicle: "Vehicle",
            },
            placeholders: {
                driver: "Select a driver",
                vehicle: "Select a vehicle",
            },
        },
        buttons: {
            reset: "Reset",
            update: "Update",
        },
        errors: {
            driversAllocation: "{{error}}, please click reset and try again",
        },
        success: {
            successAllocatedDrivers: "Successfully updated new drivers to selected vehicle",
            successUnallocatedDrivers: "Successfully unallocated all drivers from selected vehicle",
        },
        existingDrivers: "Existing Drivers:",
        isLoading: "Loading...",
    },
    preferences: {
        headings: {
            preferences: "Driver Preferences",
            update: "Update driver - {{name}}",
            areYouSure: "Are you sure?",
        },
        form: {
            labels: {
                phoneNumber: "Phone Number",
                asset: "Registration",
                weekendRotation: "Weekend Rotation",
                slackId: "Slack ID",
                slackChannelId: "Slack Channel ID",
            },
            placeholders: {
                filterDrivers: "Search driver by name or email",
                phoneNumber: "(e.g. +447555555555)",
                asset: "Select an vehicle registration",
                weekendRotation: "Select a weekend rotation",
                slackId: "Enter slack ID",
                slackChannelId: "Enter slack channel ID",
                email: "Enter email",
            },
        },
        table: {
            headings: {
                name: "Name",
                phoneNumber: "Phone Number",
                asset: "Registration",
                weekendRotation: "Weekend Rotation",
                slackId: "Slack ID",
                slackChannelId: "Slack Channel ID",
                actions: "Actions",
            },
        },
        buttons: {
            save: "Save",
            edit: "Edit",
            cancel: "Cancel",
            confirm: "Confirm",
            remove: "Remove",
            add: "Add",
            syncSlackChannel: "Sync Slack Channel",
            backToSchedule: "Back to Schedule",
        },
        success: {
            updateDriver: "Successfully updated driver",
            syncData: "Successfully synchronised driver’s Slack channel id",
            slackRoomRemoved: "Successfully removed from Slack room",
        },
        errors: {
            noDrivers: "No drivers found",
            fetchDrivers: "Failed to fetch drivers",
            fetchVehicles: "Failed to fetch vehicles",
            updateDriver: "Failed to update driver",
            syncData: "Failed to synchronise driver’s Slack channel id",
            slackRoomRemoved: "Failed to remove from Slack room",
        },
        copy1: "This flow is going to make a lot of calls to Slack to synchronise driver’s Slack channel id and could take up to a minute to complete. Therefore, it should be used sparingly.",
        copy2: "You can add a list of emails below which will be notified once the flow has been run.",
    },
};
